<template>
  <!-- DatePicker 日期选择器件 -->

      <el-date-picker
        v-model="value2"
        type="daterange"
        unlink-panels
        range-separator="--"
        start-placeholder="起始日期"
        end-placeholder="结束日期"
        :shortcuts="shortcuts"
        size="small"
        @change="handleChange"
        format="YYYY/M/D"
        value-format="YYYY-M-D"
      />

</template>

<script>
export default {
  data() {
    return {
      value2: "",
      shortcuts: [
        {
          text: "最近一周",
          value: () => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            return [start, end];
          },
        },
        {
          text: "最近一月",
          value: () => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            return [start, end];
          },
        },
        {
          text: "最近三月",
          value: () => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            return [start, end];
          },
        },
      ],
    };
  },

  methods: {
    handleChange(value) {
      this.$emit("select-change", value)
    },
  },
};
</script>

<style scoped>

</style>
