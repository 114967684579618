<template>
  <router-view></router-view>
</template>

<script>
export default {
  created() {
    this.$store.commit("initUser")
  },
};
</script>

<style>
::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 0;
}
::webkit-scrollabr {
  -webkit-appearance: none;
  width: 10px;
  height: 10px;
}
</style>
