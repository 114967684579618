<template>
  <!-- 查询供应商合同/付款/发票情况 
  <span>合同列表</span>
   <el-divider border-style="dashed" />
<div>
  <el-table
    :data="fcont"
    style="width: 100%"
    show-summary
    sum-text="合计"
  >
    <el-table-column prop="contract_signt_time" label="日期" width="180" />
    <el-table-column prop="contract_name" label="名称" width="180" />
    <el-table-column prop="contract_amount" label="金额" />
  </el-table>
</div>
<span>已支付列表</span>
 <el-divider border-style="dashed" />
<div>
  <el-table
    :data="fkrequest"
    style="width: 100%"
    show-summary
    sum-text="合计"
  >
    <el-table-column prop="sq_date" label="日期" width="180" />
    <el-table-column prop="fk_text" label="摘要" width="180" />
    <el-table-column prop="sq_amount" label="金额" />
  </el-table>
</div>
<span>发票列表</span>
 <el-divider border-style="dashed" />
<div>
  <el-table
    :data="inputinvoice"
    style="width: 100%"
    show-summary
    sum-text="合计"
  >
    <el-table-column prop="billing_date" label="日期" width="180" />
    <el-table-column prop="goods_naem" label="货品名称" width="180" />
    <el-table-column prop="total_price_tax" label="金额" />
  </el-table>
</div>
 <el-divider border-style="dashed" />-->

  <div class="container h6">
    <table class="table text-center align-middle">
      <tr>
        <td
          class="align-middle font-weight-bold"
          style="height: 2em; border: 1.5px solid #000"
        >
          名称
        </td>
        <td
          class="align-middle font-weight-bold"
          style="border: 1.5px solid #000"
        >
          数额
        </td>
        <td
          class="align-middle font-weight-bold"
          style="border: 1.5px solid #000"
        >
          说明
        </td>
      </tr>
      <tr>
        <td
          class="align-middle font-weight-bold"
          style="height: 2em; border: 1.5px solid #000"
        >
          合同值
        </td>
        <td class="align-middle text-right" style="border: 1.5px solid #000">
          {{ toThousands(dataobj.fcont) }}
        </td>
        <td
          class="align-middle text-left"
          style="border: 1.5px solid #000"
        ></td>
      </tr>
      <tr>
        <td
          class="align-middle font-weight-bold"
          style="height: 2em; border: 1.5px solid #000"
        >
          已支款
        </td>
        <td class="align-middle text-right" style="border: 1.5px solid #000">
          {{ toThousands(dataobj.fkrequest) }}
        </td>
        <td
          class="align-middle text-left"
          style="border: 1.5px solid #000"
        ></td>
      </tr>
      <tr>
        <td
          class="align-middle font-weight-bold"
          style="height: 2em; border: 1.5px solid #000"
        >
          已开发票
        </td>
        <td class="align-middle text-right" style="border: 1.5px solid #000">
          {{ dataobj.inputinvoice }}
        </td>
        <td
          class="align-middle text-left"
          style="border: 1.5px solid #000"
        ></td>
      </tr>
      <tr>
        <td
          class="align-middle font-weight-bold"
          style="height: 2em; border: 1.5px solid #000"
        >
          差/多发票
        </td>
        <td class="align-middle text-right" style="border: 1.5px solid #000">
          {{ this.dataobj.invoicerequest }}
        </td>
        <td class="align-middle text-left" style="border: 1.5px solid #000">
          公式：已开发票-已支款
        </td>
      </tr>
      <tr>
        <td
          class="align-middle font-weight-bold"
          style="height: 2em; border: 1.5px solid #000"
        >
          已支付占比
        </td>
        <td class="align-middle text-right" style="border: 1.5px solid #000">
          {{ this.dataobj.requestfcont }}%
        </td>
        <td class="align-middle text-left" style="border: 1.5px solid #000">
          公式：已支款/合同值
        </td>
      </tr>
      <tr>
        <td
          class="align-middle font-weight-bold"
          style="height: 2em; border: 1.5px solid #000"
        >
          支未付款
        </td>
        <td class="align-middle text-right" style="border: 1.5px solid #000">
          {{ dataobj.weizhifu }}
        </td>
        <td class="align-middle text-left" style="border: 1.5px solid #000">
          公式：合同值-已支款
        </td>
      </tr>
      <tr>
        <td
          class="align-middle font-weight-bold"
          style="height: 2em; border: 1.5px solid #000"
        >
          申请付款金额
        </td>
        <td class="align-middle text-right" style="border: 1.5px solid #000">
          {{ toThousands(supplier_info_id.jh_amount) }}
        </td>
        <td
          class="align-middle text-left"
          style="border: 1.5px solid #000"
        ></td>
      </tr>
      <tr>
        <td
          class="align-middle font-weight-bold"
          style="height: 2em; border: 1.5px solid #000"
        >
          占比
        </td>
        <td class="align-middle text-right" style="border: 1.5px solid #000">
          {{
            zhangbi(
              dataobj.fcont,
              dataobj.fkrequest + supplier_info_id.jh_amount
            )
          }}%
        </td>
        <td class="align-middle text-left" style="border: 1.5px solid #000">
          公式：（申请付款金额+已支款）/合同值
        </td>
      </tr>
      <tr>
        <td
          class="align-middle font-weight-bold"
          style="height: 2em; border: 1.5px solid #000"
        >
          审批付款金额
        </td>
        <td class="align-middle text-right" style="border: 1.5px solid #000">
          {{ toThousands(supplier_info_id.sp_amount) }}
        </td>
        <td
          class="align-middle text-left"
          style="border: 1.5px solid #000"
        ></td>
      </tr>
      <tr>
        <td
          class="align-middle font-weight-bold"
          style="height: 2em; border: 1.5px solid #000"
        >
          占比
        </td>
        <td class="align-middle text-right" style="border: 1.5px solid #000">
          {{
            zhangbi(
              dataobj.fcont,
              dataobj.fkrequest + supplier_info_id.sp_amount
            )
          }}%
        </td>
        <td class="align-middle text-left" style="border: 1.5px solid #000">
          公式：（审批付款金额+已支款）/合同值
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
//import axios from "axios";
export default {
  props: ["supplier_info_id", "project_id"],
  data() {
    return {
      fcont: [], //合同
      fkrequest: [], //付款
      inputinvoice: [], //发票

      dataobj: {
        fcont: 0, //合同值
        fkrequest: 0, //已支付
        inputinvoice: 0, //发票
        requestfcont: 0, //支付占比
        invoicerequest: 0, //发票与支付差额
        weizhifu: 0, //合同值 - 已支付  =  未支付
      },
    };
  },
  created() {
    //查询供应商合同/付款/发票情况
    this.loadfklist();
  },
  watch: {
    supplier_info_id() {
      //查询供应商合同/付款/发票情况
      this.loadfklist();
    },
  },
  methods: {
    //查询供应商合同/付款/发票情况
    loadfklist() {
      //查询供应商合同/付款/发票情况
      this.axios
        .get("admin/SupplierInfo/getconstantsfkinvoice", {
          params: {
            supplier_id: this.supplier_info_id.supplier_id,
            project_id: this.project_id,
          },
          headers: {
            token: window.sessionStorage.getItem("token"),
          },
        })
        .then((response) => {
          //  this.fcont = response.data.data.fcont;
          // this.fkrequest = response.data.data.fkrequest;
          // this.inputinvoice = response.data.data.inputinvoice;

          // this.dataobj = response.data.data
          //未支付
          this.dataobj.weizhifu = this.toThousands(
            response.data.data.fcont - response.data.data.fkrequest
          );
          //合同值
          this.dataobj.fcont = response.data.data.fcont;
          //已支付
          this.dataobj.fkrequest = response.data.data.fkrequest;
          //发票
          this.dataobj.inputinvoice = this.toThousands(
            response.data.data.inputinvoice
          );
          //支付占比 计算
          this.dataobj.requestfcont = this.zhangbi(
            response.data.data.fcont,
            response.data.data.fkrequest
          );

          //发票与支付差额
          this.dataobj.invoicerequest = this.toThousands(
            response.data.data.inputinvoice - response.data.data.fkrequest
          );
        })
        .catch((error) => {
          console.log(error);
        })
        .then(() => {
          // 总是会执行
        });
    },
    //格式化数字
    toThousands(cellValue) {
      return cellValue.toLocaleString("zh", {
        style: "currency",
        currency: "CNY",
      });
    },
    //占比
    zhangbi(fcont, fkrequest) {
      if (fcont == 0 && fkrequest > 0) {
        return 100;
      }
      if (fcont == 0 && fkrequest == 0) {
        return 0;
      }
      if (fcont > 0 && fkrequest == 0) {
        return 0;
      }
      if (fcont > 0 && fkrequest > 0) {
        return Math.round((fkrequest / fcont) * 10000) / 100;
      }
    },
  },
};
</script>
<style></style>
