export default {
    toplist: {
        active: "0",
        list: [
          {
            name: "首页",
            subActive: "0",
            submenu: [
              {
                name: "后台首页",
                pathname:"index",
              },
              {
                name: "项目设置",
                pathname:"",
              },
              {
                name: "财务报表",
                pathname:"",
              },
            ],
          },
          {
            name: "合同管理",
            subActive: "0",
            submenu: [
              {
                name: "收入合同",
                pathname:'contract_scontract_list'
              },
              {
                name: "付款合同",
                pathname:'contract_fcontract_list'
              },
            ],
          },
          {
            name: "付款管理",
            subActive: "0",
            submenu: [
              {
                name: "付款申请",
                pathname:'payments_spayments_list'
              },
              {
                name: "支付登记",
                pathname:'payments_zpayments_list'
              },
            ],
          },
          {
            name: "发票管理",
            subActive: "0",
            submenu: [
              {
                name: "销项发票",
                pathname:'',
              },
              {
                name: "进项发票",
                pathname:'',
              },
            ],
          },
          {
            name: "银行流水",
            subActive: "0",
            submenu: [
              {
                name: "付款账户",
                pathname:'',
              },
              {
                name: "往来账户",
                pathname:'',
              },
            ],
          },
        ],
      }
    
}
