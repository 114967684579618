import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import installElementPlus from './plugins/element'
import print from 'vue3-print-nb'


//全局配置文件
import globalconf from './common/config/config'

import qjcbfl from './components/common/chenbenfenli.vue'    //成本分类组件
import supplierinfo from './components/common/supplierinfo.vue'  //供应商列表组件
import department from './components/common/department.vue' //部门列表组件
import invoicebuys from './components/common/invoicebuys.vue' //买票购买方列表组件
import sfbanks from './components/common/sfbankslist.vue' //付款银行列表组件
import getsupplierfkinfo from './components/fkrequext/supplierfkinfo.vue'  //查询供应商合同/付款/发票情况

import fileupload from './components/common/fileupload.vue' //文件上传组件
import pdfview from './components/common/pdfview.vue' //查看附件组件
import datepickervue from './components/common/datepickervue.vue'  // DatePicker 日期选择器

const app = createApp(App)
//全局配置文件
app.config.globalProperties.confmenu = globalconf
app.component('select-cbfl', qjcbfl)    //成本分类全局组件
app.component('supplier-info',supplierinfo)   //供应商列表全局组件
app.component('department-select',department)  //部门列表组件
app.component('invoicebuys-select',invoicebuys)  //买票购买方列表组件
app.component('sfbanks-select',sfbanks)  //付款银行列表组件
app.component('file-upload',fileupload)//文件上传组件
app.component('pdf-view',pdfview)//查看附件组件
app.component('get-supplier-fkinfo',getsupplierfkinfo) ////查询供应商合同/付款/发票情况
app.component('date-picker',datepickervue) // DatePicker 日期选择器

installElementPlus(app)
app.use(print).use(store).use(router).use(VueAxios, axios).mount('#app')