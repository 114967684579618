let routes = [
  {
    // path: '/layout',
    //name: 'layout',
    redirect: { name: "index" },
    component: "layout",
    children: [
      {
        //  path: '/index',
        // name: 'index',
        meta: { title: "后台首页" },
        component: "index/index",
      },
      {
        //  path: '/index',
        // name: 'index',
        meta: { title: "财务报表" },
        component: "index/statement",
      },
      {
        // path: '/scontract',
        // name: 'scontract',
        meta: { title: "收入合同" },
        component: "contract/scontract/list",
      },
      {
        //  path: '/scontract',
        // name: 'scontract',
        meta: { title: "新增收入合同" },
        component: "contract/scontract/create",
      },
      {
        //  path: '/scontract',
        // name: 'scontract',
        meta: { title: "修改收入合同" },
        component: "contract/scontract/update",
      },
      {
        //  path: '/fcontract',
        // name: 'fcontract',
        meta: { title: "付款合同" },
        component: "contract/fcontract/list",
      },
      {
        //  path: '/fcontract',
        // name: 'fcontract',
        meta: { title: "新增付款合同" },
        component: "contract/fcontract/create",
      },
      {
        //  path: '/fcontract',
        // name: 'fcontract',
        meta: { title: "修改付款合同" },
        component: "contract/fcontract/update",
      },
      {
        // path: '/spayments',
        // name: 'spaymentslist',
        meta: { title: "收款管理列表" },
        component: "incomerecord/list",
      },
      {
        // path: '/spayments',
        // name: 'spaymentslist',
        meta: { title: "新增收款管理" },
        component: "incomerecord/create",
      },
      {
        // path: '/spayments',
        // name: 'spaymentslist',
        meta: { title: "申请付款" },
        component: "payments/spayments/list",
      },
      {
        // path: '/spayments',
        // name: 'spayments',
        meta: { title: "新增付款申请" },
        component: "payments/spayments/create",
      },
      {
        // path: '/spayments',
        // name: 'spayments',
        meta: { title: "修改付款申请" },
        component: "payments/spayments/update",
      },
      {
        // path: '/spayments',
        // name: 'spayments',
        meta: { title: "审批付款" },
        component: "payments/rpayments/list",
      },
      {
        // path: '/spayments',
        // name: 'spayments',
        meta: { title: "凭证审核" },
        component: "payments/kpayments/list",
      },
      {
        // path: '/zpayments',
        //  name: 'zpayments',
        meta: { title: "支付登记列表" },
        component: "payments/zpayments/list",
      },
      {
        // path: '/zpayments',
        //  name: 'zpayments',
        meta: { title: "支付登记确认" },
        component: "payments/zpayments/zpupdate",
      },
      {
        // path: '/zpayments',
        //  name: 'zpayments',
        meta: { title: "资金申请" },
        component: "payments/zjjhsq/list",
      },
      {
        // path: '/zpayments',
        //  name: 'zpayments',
        meta: { title: "资金审批" },
        component: "payments/zjjhsp/list",
      },
      {
        // path: '/invoice',
        // name: 'invoice',
        meta: { title: "进项发票列表" },
        component: "invoice/inputinvoice/list",
      },
      {
        // path: '/invoice',
        // name: 'invoice',
        meta: { title: "新增进项发票" },
        component: "invoice/inputinvoice/create",
      },
      {
        // path: '/invoice',
        // name: 'invoice',
        meta: { title: "销项发票" },
        component: "invoice/salesinvoice/list",
      },
      {
        //  path: '/banks',
        // name: 'banks',
        meta: { title: "收付款账户" },
        component: "banks/sfbanks/list",
      },
      {
        //  path: '/banks',
        // name: 'banks',
        meta: { title: "股东款账户" },
        component: "banks/wlbanks/list",
      },
      {
        //  path: '/banks',
        // name: 'banks',
        meta: { title: "往来账户" },
        component: "banks/zjwlbanks/list",
      },
      {
        //  path: '/banks',
        // name: 'banks',
        meta: { title: "往来转款" },
        component: "banks/wltransfer/list",
      },
      {
        //  path: '/banks',
        // name: 'banks',
        meta: { title: "用户管理" },
        component: "set/manager/list",
      },
    ],
  },
  {
    path: "/",
    // name: 'Login',
    component: "login/index",
  },
  {
    // path: '/projectlist',  项目列表
    // name: 'ProjectList',
    component: "project/ProjectList",
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: { name: "login" },
  },
];

// 获取路由信息方法
let getGouters = function () {
  // 自动生成路由
  createRoute(routes);
  console.log(routes);
  return routes;
};

function createRoute(arr) {
  for (let i = 0; i < arr.length; i++) {
    if (!arr[i].component) return;
    // 去除index
    let val = getValue(arr[i].component);
    // 生成name
    arr[i].name = arr[i].name || val.replace(/\//g, "_");
    // 生成path
    arr[i].path = arr[i].path || `/${val}`;
    //自动生成component
    let componentFun = import(`../../views/${arr[i].component}.vue`);
    arr[i].component = () => componentFun;
    if (arr[i].children && arr[i].children.length > 0) {
      createRoute(arr[i].children);
    }
  }
}
// 去除index
function getValue(str) {
  // str = login/index
  // 获取最后一个/的索引
  let index = str.lastIndexOf("/");
  // 获取最后一个/后面的值
  let val = str.substring(index + 1, str.length);
  // 判断是不是index结尾
  if (val === "index") {
    return str.substring(index, -1);
  }
  return str;
}

export default getGouters();
