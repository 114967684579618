<template>
<!-- 文件列表  -->
  <el-table :data="tableData.data">
    <el-table-column prop="id" label="序号" width="300" />
    <el-table-column prop="file_name" label="文件名称" width="300" />
    <el-table-column prop="create_time" label="上传时间" width="150" />
    <el-table-column label="操作" width="150" align="center">
      <template #default="scope">
        <el-button-group>
          <el-button size="small" plain @click="openPdfFile(scope.row.url_path)"
            >查看</el-button
          >
          <el-button size="small" plain type="danger" @click="deletePdfFile(scope.row.id)">删除</el-button>
        </el-button-group>
      </template>
    </el-table-column>
  </el-table>

  <!-- 查看PDF文件 -->
        <el-dialog v-model="pdfurlVisible" width="100%"
                    fullscreen
                    @closed="pdfurl=''"
                    >
        <div class="text-center" style="height:2000px">
           <iframe :src="pdfurl" width="100%" height="100%"></iframe>
        </div>
      </el-dialog>
</template>
<script>
import axios from "axios";
//import { ElMessageBox } from "element-plus";
export default {
  props: ["fileclass", "prdclassid"],
  data() {
    return {
        pdfurlVisible:false,
        pdfurl:'',
      //附件列表数据
      tableData: {
        data: [],
      },
    }
  },
  watch:{
      prdclassid(){
           this.getFileList()
      }
  },
  created(){
      this.getFileList()
  },
  methods: {

    //删除PDF文件 @click="deletePdfFile(scope.row.id)"
    deletePdfFile(rowid){
       axios
        .delete("admin/AccessoryFile/"+rowid, {
          params: {},
          headers: {
            token: window.sessionStorage.getItem("token"),
          },
        })
        .then((response) => {
          console.log("******11", response.data.data, "11******");
           this.getFileList()
        })
        .catch((error) => {
          console.log(error);
        })
        .then(() => {
          // 总是会执行
        });
    },
    //查看PDF文件
      openPdfFile(url){
          this.pdfurl = url;
          this.pdfurlVisible = true;
      },
    //获取对应记录的附件
    getFileList() {
      console.log('/*/*/*/*',this.prdclassid)
      axios
        .get("admin/AccessoryFile", {
          params: {
            fileclass_id: this.fileclass,
            record_id: this.prdclassid,
          },
          headers: {
            token: window.sessionStorage.getItem("token"),
          },
        })
        .then((response) => {
          console.log("******11", response.data.data, "11******");
          this.tableData.data = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .then(() => {
          // 总是会执行
        });
    },
  },
};
</script>