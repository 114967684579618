<template>
<!--成本分类 级联选项控件-->
  <div>
    <el-cascader
      ref="myCascader"
      :placeholder="placeholder"
      v-model="value"
      :options="options"
      :props="props"
      @change="handleChange"
      size="small"
      :style="selectwidth"
    ></el-cascader>
  </div>
</template>

<script>
export default {
  //参数：checks  ----》设置el-scacader 可以选择任意一级选项 
  //参数：selectw  ----》设置el-cascader的宽度
  props:['placeholder','checks','selectw'],
  data() {
    return {
      value: [],
      selectwidth:'width: 260px',
      props: {
        value: "id",
        children: "child",
        label:"name",
        checkStrictly: false,
      },
      options: [],
    };
  },
  watch:{
      placeholder(){
        // 默认成本分类 /20/30/35 格式  转换成 [20,30,35] 邦定el-cascader v-model属性
      let temparray = []
        this.placeholder.split('/').forEach(element => {
          if(element){
            temparray.push(parseInt(element))
          }
        });
      this.value = temparray
      }
  },
  created() {
//如果传入了width的参数
if(this.selectw){
  this.selectwidth='width: '+this.selectw
}    

//如果传入了checks参数
    if(this.checks){
      this.props.checkStrictly=this.checks
    }else
    {
      this.props.checkStrictly=false;
    }
    
    this.__init();
  },
  methods: {
    //初始化
    __init() {
      this.axios
        .get("admin/Category", {
          params: {
            project_id: window.sessionStorage.getItem("project_ids"),
          },
          headers: {
            token: window.sessionStorage.getItem("token"),
          },
        })
        .then((response) => {
          console.log(response.data.data);
          this.options=response.data.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .then(() => {
          // 总是会执行
        });
    },
    handleChange(value) {
      this.$emit('select-change',value);
    },
  },
};
</script>

<style scoped>
.block {
  margin: 1rem;
}
.demonstration {
  margin: 1rem;
}
.el-cascader-menu {
  height: 20px;
  width: 200px;
}
</style>