 //import { createRouter, createWebHistory } from 'vue-router' 
 import { createRouter, createWebHashHistory } from 'vue-router' 

import routes from '../common/config/router.js'


const router = createRouter({
 // history: createWebHistory(process.env.BASE_URL),
 history: createWebHashHistory(),
  routes
}) 

export default router