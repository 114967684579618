<template>
<!--付款银行下拉选项控件 级联选项控件-->
  <div>
    <el-select v-model="selectvalue" 
               size="small"
               @change="handleChange"
               :placeholder="placeholder"
              >
    <el-option
      v-for="item in options"
      :key="item.id"
      :label="item.account_name"
      :value="item.id"
    >
    </el-option>
  </el-select>
  </div>
</template>

<script>
export default {
  props:['placeholder','banksclass'],
  data() {
    return {
      options: [],
      selectvalue:''
    };
  },
    watch:{
    placeholder(){
      this.selectvalue = parseInt(this.placeholder)
    }
  },
  created() {
    this.__init();
  },
  methods: {
    //初始化
    __init() {
      this.axios
        .get("admin/SfBanks", {
          params: {
            project_id: window.sessionStorage.getItem("project_ids"),
            banksclass:this.banksclass  // 1-表示收付款账户  2表示往来款账户
          },
          headers: {
            token: window.sessionStorage.getItem("token"),
          },
        })
        .then((response) => {
          console.log(response.data.data);
          this.options=response.data.data;

        })
        .catch((error) => {
          console.log(error);
        })
        .then(() => {
          // 总是会执行
        });
    },

    handleChange(value) {
      let objVal = {};
      this.options.forEach((dataid)=>{
        if(dataid.id==value){
          objVal = dataid
        }
      })
      this.$emit('select-change',objVal);
    },
  },
};
</script>

<style scoped>
.block {
  margin: 1rem;
}
.demonstration {
  margin: 1rem;
}
.el-cascader-menu {
  height: 20px;
  width: 200px;
}
</style>