export default {
  state: {
    toplist: {
      active: "0",
      list: [],
    },
  },
  getters: {},
  mutations: {
    //生成菜单
    createNavBar(state, menus) {
      let list = menus.map((item) => {
        let submenu = "";
        if (item.child) {
          submenu = item.child.map((v) => {
            return {
              name: v.name,
              pathname: v.desc,
            };
          });
        }

        return {
          name: item.name,
          subActive: "0",
          submenu: submenu,
        };
      });
      state.toplist.list = list;
      window.sessionStorage.setItem("navBar", JSON.stringify(state.toplist));
    },
  },
  actions: {},
};
