<template>
  <!--文件上传控件   admin/AccessoryFile-->
  <el-upload
    class="upload-demo w-100"
    drag
    :action="urlpaths"
    :headers="headerstoken"
    multiple
    :before-upload="uploadBefore"
    :data="prosData"
    name="file"
  >
    <div class="el-upload__text">
      拖拽文件到这里 或者 <em>单击上传文件</em>
    </div>
    <template #tip>
      <div class="el-upload__tip">
        上传文件不要过大！
      </div>
    </template>
  </el-upload>
</template>

<script>
export default {
  props: ['fileclass','prdclassid','urlpath','filedir'],        // urlpath->上传图片API接口
  data() {
    return {
      headerstoken: { token: window.sessionStorage.getItem("token") },
      urlpaths:this.urlpath,
      prosData: {
        file_name: "",
        fileclass_id: this.fileclass,
        record_id: this.prdclassid,
        filedir:this.filedir
      },
    };
  },

  methods: {
    //上传之前
    uploadBefore(file) {
      this.prosData.file_name = file.name;
    },
  },
};
</script>



<style scoped>
</style>