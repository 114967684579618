<template>
  <!--供应商列表选项控件 级联选项控件-->
  <div>
    <el-select
      v-model="selectvalue"
      size="small"
      @change="handleChange"
      :placeholder="placeholder"
    >
      <el-option
        v-for="item in tableData"
        :key="item.id"
        :label="item.supplier_name"
        :value="item.id"
      >
      </el-option>
    </el-select>
  </div>
</template>

<script>
export default {
  props: ["category_ids","placeholder"],
  data() {
    return {
      tableData: [],
      selectvalue:''
    };
  },

  watch: {
    category_ids: {
      handler() {
        this.selectvalue="";
        this.__init();
      },
    },
    placeholder() {
      this.selectvalue = this.placeholder
    }
  },
  methods: {
    //初始化
    __init() {
      this.axios
        .get("admin/SupplierInfo", {
          params: {
            project_id: window.sessionStorage.getItem("project_ids"),
            category_id: this.category_ids,
          },
          headers: {
            token: window.sessionStorage.getItem("token"),
          },
        })
        .then((response) => {
          this.tableData = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .then(() => {
          // 总是会执行
        });
    },

    //
     handleChange(value) {
      //
      let objVal = {};
      this.tableData.forEach((dataid)=>{
        if(dataid.id==value){
          objVal = dataid
        }
      })
      this.$emit('select-change',objVal);
    },
  },
};
</script>

<style>
</style>